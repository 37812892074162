<template>
  <div class="settings-content-wrapper">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <h1>Services</h1>
    <p>Configure the services your business provides.</p>
    <hr>
    <div v-if="services">
      <div class="box bunya-box card" v-for="(service, index) in services" v-bind:key="index">
        <router-link :to="{ name: 'settings-services-show', params: { id: service.id }}">
          <div>
            <div class="level is-mobile component-level-margin">
              <div class="level-left">
                <div class="level-item">
                  <h4 class="text" >{{ service.price }} {{ service.name }}</h4>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <div class="tag is-light">{{ service.duration }} minutes</div>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column is-two-thirds">
                <h6 class="subtext">{{ service.service_category.name }}</h6>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <hr>
    </div>
    <div v-else>
      <b-message type="is-danger">
        <strong>No services have been added.</strong>
      </b-message>
      <hr>
    </div>
    <div class="level">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="level-item">
          <router-link :to="{ name: 'settings-services-create' }" class="button is-primary is-fullwidth-mobile">Add Service</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  @import '../../scss/_theme-default.scss';

  .card a {
    color: $dark;
  }

  .card a:hover {
    color: lighten($dark, 20);
  }

  .card {
    background-color: #F9F9F9;
  }

  .subtext {
    color: lighten($dark, 40);
  }

</style>

<script>

import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  name: 'Services',
  data () {
    return {
      breadcrumbs: [
        { name: 'Settings', route: 'settings-home' },
        { name: 'Services', route: null }
      ]
    }
  },
  computed: {
    services () {
      return this.$store.getters['settings/services']
    }
  },
  mounted () {
    this.$store.dispatch('settings/fetchServices')
      .then(() => {})
      .catch((err) => {
        this.$buefy.toast.open({
          message: `Error: ${err.message}`,
          type: 'is-danger',
          duration: 4000,
          queue: false
        })
      })
  },
  components: { Breadcrumbs }
}
</script>
